import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const EinnahmenAusgabenRechnung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wie mache ich eine Einnahmen-Ausgaben-Rechnung?" showCalc={false} />
            <Article>
                <p>
                    Eine Einnahmen-Ausgaben-Rechnung ist unverzichtbar, wenn du einen Kredit aufnehmen willst. Die
                    Bezeichnung kommt eigentlich aus der Firmenbuchhaltung, ist aber auch die für eine Haushaltsrechnung
                    üblich. Eine solche ist notwendig, um zu wissen, wie viel Kredit du dir wirklich leisten kannst.
                    Worauf dabei zu achten ist, erfährst du in diesem Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was sind meine Einnahmen?</h2>
                <p>
                    Um zu wissen, wie hoch die monatliche{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrate
                    </Link>{" "}
                    bei deinem Einkommen sein darf, musst du auf mehrere Faktoren als nur die Einnahmen achten. Aber
                    fangen wir einmal damit an. Das Einkommen deines Haushalts setzt sich zusammen aus dem
                    Nettoeinkommen von dir und eventuell auch der{" "}
                    <Link to="/artikel/wohnkredit-zu-zweit/" target="_blank" rel="noreferrer noopener">
                        zweiten Person
                    </Link>
                    , mit der du den Kredit aufnimmst. Dazu werden weitere Einkünfte wie zum Beispiel Kinder-, Familien-
                    und Studienbeihilfen, sowie weitere Einnahmen wie Mieteinnahmen oder eine Pension gerechnet. Dabei
                    solltest du natürlich darauf achten, dass einige Einnahmen in Zukunft wegfallen könnten.
                    Unregelmäßige Einnahmen wie zum Beispiel das Urlaubsgeld können zwar dazu gezählt werden, werden
                    aber nicht von jeder Bank als regelmäßiges Einkommen gewertet.
                </p>
                <hr />

                <h2>Wie hoch sind meine Ausgaben?</h2>
                <p>
                    Das ist der Punkt der Einnahmen-Ausgaben-Rechnung, an dem die meisten sich verrechnen. Zu den
                    regelmäßigen Ausgaben zählen alle Kosten, die monatlich anfallen, um das eigene Leben zu erhalten.
                    Die Ausgaben bestehen also aus allen monatlichen Fixkosten und deinen Lebenshaltungskosten. Dazu
                    zählen:
                </p>
                <ul>
                    <li>
                        die Miete, inklusive der Betriebskosten (wobei diese eventuell nach Kreditaufnahme wegfällt und
                        durch die monatliche Rate und Betriebskosten ersetzt werden kann)
                    </li>
                    <li>Energiekosten, wie Strom, Gas und Wasser</li>
                    <li>Internet- und Telefonanschlüsse</li>
                    <li>regelmäßige Rundfunkgebühren</li>
                    <li>Versicherungen aller Art</li>
                    <li>Gesundheitskosten wie zB. auch jegliche Therapiekosten</li>
                    <li>Mobilitätskosten, also die Ausgaben für Fahrkarten, das Auto usw.</li>
                    <li>
                        Unterhaltszahlungen für Kinder bzw. alle Kosten, die für Kinder anfallen wie die
                        Kinderbetreuung, Ausgaben für die Schulbildung, Kleidung usw.
                    </li>
                    <li>Lebensmittelausgaben für den gesamten Haushalt</li>
                    <li>Ausgaben für Freizeitaktivitäten, Hobbys, wie zB. Fitnessmitgliedschaften, Kinobesuche,…</li>
                    <li>Kosten für Reisen, die regelmäßig stattfinden</li>
                    <li>Kosten für Geschenke wie Geburtstags- und Weihnachtsgeschenke, die regelmäßig anfallen</li>
                    <li>Eventuell Kosten für Haustiere, wie Arztbesuche, Futter, Medizin usw.</li>
                    <li>Abonnements wie Zeitungsabos, Musik- und Streamingdienste</li>
                </ul>
                <p>
                    Zusätzlich sollte man noch überlegen, ob eventuell in mehreren Jahren noch größere Anschaffungen
                    geplant sind. Waschmaschinen und Geschirrspülmaschinen können kaputtgehen, Autos auch, Kinder werden
                    älter und ihre Bedürfnisse verändern sich. Für solche Fälle muss unbedingt ein großer Puffer
                    eingerechnet werden. Es lohnt sich jedenfalls, sich Gedanken darüber zu machen, wie die monatlichen
                    Fixkosten in fünf Jahren aussehen könnten.
                </p>
                <hr />

                <h2>Die Einnahmen-Ausgaben-Rechnung</h2>
                <p>
                    Der Name ist Programm. Jetzt ziehst du ganz schonungslos alle deine Ausgaben von deinen Einnahmen ab
                    und siehst dann, wie viel Geld übrig bleibt. Wie erwähnt sollte hier immer ein Puffer mitgerechnet
                    werden, falls irgendetwas kaputt geht. Auch jährliche Zahlungen wie Servicepauschalen solltest du
                    mitdenken, sie summieren sich schneller, als man denkt. Tipp: Bevor du dich an die Haushaltsrechnung
                    machst, kannst du bereits deine gewünschte Kreditsumme und Laufzeit in unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    eintragen und die prognostizierte monatliche Rate mit deinem Ergebnis nach der genauen Berechnung
                    vergleichen. Dann weißt du, ob du dir wirklich so viel leisten kannst, wie du denkst, welche Rate
                    und Laufzeit realistisch sind und bist top vorbereitet auf dein Gespräch mit der Bank.
                </p>
                <hr />

                <h2>Wozu die Einnahmen-Ausgaben Rechnung?</h2>
                <p>
                    Die Genauigkeit und Ehrlichkeit bei der Einnahmen-Ausgaben-Rechnung ist wirklich unverzichtbar,
                    damit sie sinnvoll ist. Häufig werden solche Haushaltsrechnungen sehr stark beschönigt, gar nicht
                    unbedingt für die Bank, sondern gerne auch für einen selbst. Eine Bank möchte jedoch, bevor sie
                    einen Kredit vergibt, sicherstellen, dass sie das geliehene Geld wirklich zurück bekommt. Wenn man
                    also eine genaue Haushaltsrechnung vorweisen kann, in der zu sehen ist, dass man genügend Geld für
                    die monatliche Kreditrate zur Verfügung hat, bekommt man eher einen Kredit gewährt. Durch eine
                    solche Einnahmen-Ausgaben-Rechnung ist es möglich, den möglichen Raum für die{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgungsrate
                    </Link>{" "}
                    festzustellen. Und das ist auch in deinem Interesse, schließlich möchtest du dir mit einem Kredit
                    keine zukünftigen Schwierigkeiten bereiten und ihn problemlos zurückzahlen können.
                </p>
                <p>
                    Ab dem Jahr{" "}
                    <Link
                        to="/artikel/unsichere-zeiten-auswirkungen-auf-den-hauskauf/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        2022
                    </Link>{" "}
                    wird eine Einnahmen-Ausgaben-Rechnung besonders wichtig, weil die Inflationsrate steigt und die
                    Ausgaben in sehr kurzer Zeit merkbar höher werden. Das betrifft nicht nur die Energiekosten, sondern
                    zB. Auch die Lebensmittel- und Autokosten. Auch die Mieten und Betriebskosten sind dieses Jahr
                    vielerorts angehoben worden. Das macht eine genaue Einnahmen-Ausgaben-Rechnung unvermeidbar, da man
                    sich nicht mehr auf alte Berechnungen stützen kann.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"einnahmenAusgabenRechnung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"einnahmenAusgabenRechnung"}
                heading={"Wie mache ich eine Einnahmen-Ausgaben-Rechnung?"}
            />
        </Layout>
    );
};

export default EinnahmenAusgabenRechnung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.einnahmen-ausgaben-rechnung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
